<template>
  <services-list/>
</template>

<script>
  import ServicesList from '@/components/services-list'

  export default {
    name: 'Home-list',

    components: {
      ServicesList,
    },
  }
</script>
