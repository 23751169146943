import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import {Resource} from 'vue-resource'
const CheckProhibition = new Resource('check-prohibition')

export default new Vuex.Store({
  state: {
    fetching: false,
    fetchedData: null,
  },

  mutations: {
    setData(state, val = null) {
      state.fetchedData = val
    }
  },

  actions: {
    checkProhibition({state, commit}, data = {}) {
      if (!state.fetching) {
        commit('setData', null)
        CheckProhibition.save(data)
            .then(({body}) => {
              commit('setData', body)
            })
            .finally(() => state.fetching = false)
      }
    }
  },
  modules: {
  }
})
