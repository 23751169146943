<template>
  <v-app>
    <v-app-bar app color="primary" dark height="64px">
      <div class="d-flex align-center">
        <v-img alt="app logo" class="shrink mr-2" contain src="@/assets/logo-2.png" transition="scale-transition" width="40"/>
        <div class="ml-2">
          O‘zbekiston Respublikasi Vazirlar Mahkamasi huzuridagi <br>
          Agrosanoat majmui ustidan nazorat qilish inspeksiyasi
        </div>
      </div>
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
    <v-snackbar v-model="notification" :timeout="5000" :color="notificationError ? 'error' : 'success'" top center>
      {{ notificationText }}
      <template v-slot:action="{ attrs }">
        <v-btn text icon v-bind="attrs" @click="notification = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-footer>
      {{year}} ·
      O‘zbekiston Respublikasi Vazirlar Mahkamasi huzuridagi Agrosanoat majmui ustidan nazorat qilish inspeksiyasi ·
      <a href="https://agroinspeksiya.uz">agroinspeksiya.uz</a>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'app',
  data() {
    return {
      year: (new Date).getFullYear(),

      notificationText: '',
      notification: false,
      notificationError: false,
    }
  },

  created() {
    this.$eventBus.$on('notification:success', (val) => {
      this.notificationText = val
      this.notification = true
      this.notificationError = false
    })
    this.$eventBus.$on('notification:error', (val) => {
      this.notificationText = val
      this.notification = true
      this.notificationError = true
    })
  }
};
</script>
