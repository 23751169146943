<template>
  <v-container>
    <breadcrumbs current="Ta'qiqni tekshirish"></breadcrumbs>
    <h1>
      Qishloq xo‘jaligi, melioratsiya va yo‘l-qurilish texnikalari hamda o‘ziyurar mashinalarga taqiqqa olinganligi
      haqida ma'lumot olish
    </h1>
    <v-form ref="form" class="mt-6"
            v-model="valid"
            @submit.prevent="submit"
            lazy-validation>
      <v-row>
        <v-col cols="12">
          <v-text-field
              v-model="form.transport_number"
              :rules="requiredRule" outlined
              label="Davlat raqami belgisi"
              required
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
              v-model="form.passport_id"
              :rules="requiredRule" outlined
              label="Texnik passport / guvohnoma raqami"
              required
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="mb-3">
          <v-btn :disabled="!valid || fetching" color="success" class="mr-4" type="submit"
                 :loading="fetching"
          >
            Tekshirish
          </v-btn>
        </v-col>

        <v-col v-if="!fetching && fetchedData" cols="12" class="font-weight-bold blue-grey lighten-4">
          <h5 class="text-h5 mb-4">Natija</h5>
          <div class="font-weight-bold">Davlat Raqami belgisi: {{fetchedData.transport_number}}</div>
          <div class="font-weight-bold">Texnik passport \ Guvohnoma: {{fetchedData.passport_id}}</div>

          <div class="font-weight-bold">
            Ta'qiq holati:
            <span v-if="fetchedData.status === 'unknown'">Ma'lumotlar topilmadi</span>
            <span v-else-if="fetchedData.status === 'lock'" class="red--text darken-3">Ta'qiqda</span>
            <div v-else class="green--text darken-2">Ta'qiqda emas</div>

          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import {mapState} from 'vuex'
import breadcrumbs from '@/components/breadcrumbs'

export default {
  name: 'Home-list',
  components: {
    breadcrumbs,
  },


  data() {
    return {
      valid: true,
      requiredRule: [
        v => !!v || 'Maydon to\'ldirishi shart',
        v => (v && v.toString().length <= 11) || 'Xato ma\'lumot kiritildi',
      ],

      form: {
        transport_number: '',
        passport_id: '',
      },
    }
  },

  computed: {
    ...mapState(['fetching', 'fetchedData'])
  },

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('checkProhibition', this.form)
      }
    },
  },
}
</script>
