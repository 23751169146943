<template>
  <v-breadcrumbs :items="menus" divider="\" class="px-0"></v-breadcrumbs>
</template>

<script>
export default {
  name: 'breadcrumb-list',

  props: {
    current: {
      required: true,
      type: String,
    }
  },

  data() {
    return {
      items: [
        {
          text: 'Asosiy sahifa',
          exact: true,
          to: {name: 'services-list'},
        },
      ]
    }
  },

  computed: {
    menus() {
      return [...this.items, {
        text: this.current,
      }]
    }
  },
}
</script>