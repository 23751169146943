<template>
  <v-container>
    <h1 class="my-4">Xizmatlardan birini tanlang:</h1>
    <v-row>
      <v-col v-for="service of services" :key="service.id" cols="6">
        <v-card class="pa-5">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title v-text="service.name"></v-card-title>
              <v-card-subtitle v-text="service.description"></v-card-subtitle>
              <v-card-actions>
                <v-btn color="primary" rounded class="pl-4"
                       :to="{name: service.route}"
                >
                  Xizmatdan foydalanish <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
              </v-card-actions>
            </div>
            <v-avatar size="72" tile class="mt-4">
              <v-img src="@/assets/logo.png"></v-img>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HelloWorld',

  data() {
    return {
      services: [
        {
          name: 'Texnikaning ta\'qiqqa olinglanligini tekshirish',
          description: 'Qishloq xo‘jaligi, melioratsiya va yo‘l-qurilish texnikalari hamda o‘ziyurar mashinalarga taqiqqa olinganligi haqida ma\'lumot olish',
          route: 'check-prohibition',
        },
      ],
    }
  },
}
</script>
