import VueResource from 'vue-resource'

export default {
  install(VueInstance) {
    VueInstance.use(VueResource)
    VueInstance.http.options.root = process.env.VUE_APP_API_URL

    VueInstance.http.interceptors.push((request, next) => {
      request.headers.set('Content-Type', 'application/json')
      request.headers.set('Accept', 'application/json')
      next()
    })

    VueInstance.http.interceptors.push(function (request, next) {
      next((response) => {
        if (!(response.ok && response.body.code === 0)) {
          let message = ''

          if (response.body.code !== 0) {
            switch (response.body.code) {
              case -1:
                message = 'Maydonlar xato to\'ldirilgan'
                break
              default:
                message = 'Xatolik ro\'y berdi! Keyinroq urinib ko\'ring'
            }
          } else {
            switch (response.status) {
              case 422:
                message = 'Maydonlar xato to\'ldirilgan'
                break
              case 429:
                message = 'Siz juda ko\'p so\'rov yuboryapsiz, sal keyinroq urnab ko\'ring'
                break
              default:
                message = 'Xatolik ro\'y berdi! Keyinroq urinib ko\'ring'
            }
          }

          VueInstance.$eventBus.$emit('notification:error', message)
          throw Error
        }
      })
    })
  }
}
